<template>
  <v-row
    justify="space-between"
    class="head-search ma-0">
    <v-col
      cols="12"
      lg="4"
      class="d-flex align-center date-picker-col">
      <date-range-picker v-model="filter.dates" />
    </v-col>
    <v-col
      cols="6"
      lg="2"
      class="d-flex align-center date-picker-col">
      <WarehouseSelect
        v-model="filter.warehouse"
        :items="warehouses"
        item-text="name"
        item-value="id"
        :menu-props="{ offsetY: true }"
        label="Warehouses"
        outlined />
    </v-col>
    <v-col
      cols="6"
      lg="2"
      class="d-flex align-center date-picker-col">
      <v-select
        v-model="filter.state"
        :items="states"
        item-text="text"
        item-value="value"
        :menu-props="{ offsetY: true }"
        label="State"
        class="select-warehouse"
        auto-select-first
        outlined
        hide-details
        dense />
    </v-col>
    <v-col
      cols="12"
      lg="4"
      class="d-flex justify-end align-center date-picker-col">
      <search-box
        v-model="filter.searchInput"
        @on-search="find()" />
    </v-col>
  </v-row>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import DateRangePicker from './DateRangePicker.vue'

export default {
  components: {
    DateRangePicker,
    SearchBox,
    WarehouseSelect
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    warehouses: {
      type: Array,
      required: true
    },
    states: {
      type: Array,
      required: true
    }
  },
  computed: {
    filter: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    find () {
      this.$emit('on-find')
    }
  }
}
</script>
