import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class DHLProvider extends HttpRequest {
  getRates (payload) {
    this.setHeader(getAuthToken())
    return this.post('/dhl/rates', payload)
  }

  postalCodeCheck (payload) {
    this.setHeader(getAuthToken())
    return this.get('/dhl/postal-code-check', payload)
  }

  getExpressPickup (payload) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/dhl/express-pick-up', payload)
  }

  getPickupSummary (payload) {
    this.setHeader(getAuthToken())
    return this.get('/dhl/express-pick-up/summary', payload)
  }

  syncTracking (store) {
    this.setHeader(getAuthToken())
    return this.get(`/public/${store.id}/dhl/update-tracking`)
  }

  createShipment (payload) {
    this.setHeader(getAuthToken())

    return this.post('/dhl/shipment', payload)
  }
}

export default DHLProvider
