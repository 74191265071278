<template>
  <v-container
    class="rounded elevation-3 bg-white h-full"
    fluid>
    <v-row>
      <v-col cols="12">
        <span class="header">DHL Express Pick Up Report</span>
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      no-gutters>
      <v-col cols="12">
        <dhl-pickup-report-header
          v-model="filter"
          :warehouses="warehouses"
          :states="states"
          @on-find="find()" />
      </v-col>
      <v-col cols="9">
        <div class="d-flex">
          <v-card
            class="ml-5"
            elevation="1">
            <div class="pa-2 px-3 font-weight-medium">
              Total Shipment ({{ reportsSummary.totalShipment | showNumberFormat() }})
            </div>
          </v-card>
          <v-card
            class="ml-2"
            elevation="1">
            <div class="pa-2 px-3 font-weight-medium">
              Pending ({{ reportsSummary.pending | showNumberFormat() }})
            </div>
          </v-card>
          <v-card
            class="ml-2"
            elevation="1">
            <div class="pa-2 px-3 font-weight-medium">
              Picked Up ({{ reportsSummary.pickedUp | showNumberFormat() }})
            </div>
          </v-card>
          <v-card
            class="ml-2"
            elevation="1">
            <div class="pa-2 px-3 font-weight-medium">
              Cancel ({{ reportsSummary.cancel | showNumberFormat() }})
            </div>
          </v-card>
          <!-- <v-card
            class="ml-2"
            elevation="1">
            <div class="pa-2 px-3 font-weight-medium">
              Total Order Fee ({{ reportsSummary.totalOrderFee | showFullPriceFormat() }} THB)
            </div>
          </v-card>
          <v-card
            class="ml-2"
            elevation="1">
            <div class="pa-2 px-3 font-weight-medium">
              Total Shipping Fee ({{ reportsSummary.totalShippingFee | showFullPriceFormat() }} THB)
            </div>
          </v-card> -->
        </div>
      </v-col>
      <v-col cols="3">
        <div
          class="w-full d-flex justify-end align-center"
          style="gap: 0.4rem;">
          <v-btn
            color="secondary"
            @click="syncTracking()">
            <v-icon>mdi-cached</v-icon>
            Sync
          </v-btn>
          <v-btn
            color="secondary"
            :disabled="!totalReports"
            @click="exportDataToCSV()">
            Export ({{ totalReports }})
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="reports"
          hide-default-footer
          no-data-text="ไม่มีข้อมูลในช่วงเวลานี้"
          loading-text="กรุณารอสักครู่...">
          <template #[`item.warehouse`]="{ item }">
            <span>{{ item.warehouse.name }}</span>
          </template>
          <template #[`item.trackingNo`]="{ item }">
            <div
              v-for="(piece, indexPiece) in item.pieces"
              :key="`trackingNo-${indexPiece}`">
              {{ piece.trackingNo }}
            </div>
          </template>
          <template #[`item.pickupDate`]="{ item }">
            <div
              v-for="(piece, indexPiece) in item.pieces"
              :key="`pickupDate-${indexPiece}`">
              <span>{{ piece.pickupDate | dateTimeUserFormat }}</span>
            </div>
          </template>
          <template #[`item.pickup`]="{ item }">
            <v-icon
              v-if="item.state === 'picked_up'"
              color="success">
              mdi-check
            </v-icon>
            <span
              v-else-if="item.state === 'cancel'"
              style="color: #ef5350; font-weight: 500;">
              CANCEL
            </span>
            <v-icon
              v-else
              color="error">
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="totalPages"
          circle
          color="secondary"
          total-visible="7" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import DHLProvider from '@/resources/DHLProvider'
import DhlPickupReportHeader from '../components/DhlPickupReportHeader.vue'

const DHLService = new DHLProvider()

export default {
  components: { DhlPickupReportHeader },
  data () {
    return {
      page: 1,
      perPage: 20,
      totalPages: 0,
      totalReports: 0,
      headers: [
        {
          text: 'Order Id',
          value: 'orderId',
          align: 'center'
        },
        {
          text: 'Shipment No',
          value: 'shipmentNo'
        },
        {
          text: 'Warehouse',
          value: 'warehouse'
        },
        {
          text: 'Tracking No',
          value: 'trackingNo'
        },
        {
          text: 'Pick up Date',
          value: 'pickupDate'
        },
        {
          text: 'Pick up',
          value: 'pickup',
          align: 'center',
          sortable: false
        }
      ],
      states: [
        {
          text: 'All',
          value: null
        },
        {
          text: 'Pending',
          value: 'pending'
        },
        {
          text: 'Picked Up',
          value: 'picked_up'
        },
        {
          text: 'Cancel',
          value: 'cancel'
        }
      ],
      reports: [],
      reportsSummary: [],
      filter: {
        dates: [
          this.$dayjs().format('YYYY-MM-DD'),
          this.$dayjs().format('YYYY-MM-DD')
        ],
        warehouse: null,
        state: null,
        searchInput: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return [
        {
          name: 'All',
          id: null
        },
        ...this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
      ]
    }
  },
  watch: {
    page () {
      this.fetchDhlReports()
    }
  },
  mounted () {
    this.fetchDhlReports()
    this.fetchDhlSummary()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async fetchDhlSummary () {
      try {
        this.setLoading({ active: true })

        const query = {
          startDate: this.filter.dates[0],
          endDate: this.filter.dates[1],
          warehouse: this.filter.warehouse,
          state: this.filter.state
        }

        const { data } = await DHLService.getPickupSummary(query)

        this.reportsSummary = data || {
          pending: 0,
          pickedUp: 0,
          totalOrderFee: 0,
          totalShipment: 0,
          totalShippingFee: 0
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-SUMMARY-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async fetchDhlReports (isClear = false) {
      try {
        this.setLoading({ active: true })

        this.reports = []

        if (isClear) {
          this.page = 1
        }

        const query = {
          page: this.page,
          limit: this.perPage,
          startDate: this.filter.dates[0],
          endDate: this.filter.dates[1],
          warehouse: this.filter.warehouse,
          state: this.filter.state,
          search: this.filter.searchInput
        }

        const { data } = await DHLService.getExpressPickup(query)

        this.totalPages = data.pages
        this.totalReports = data.total
        this.reports = data.results
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async syncTracking () {
      try {
        this.setLoading({ active: true })

        await DHLService.syncTracking(this.$store.getters['Store/store'])

        this.setSnackbar({
          value: true,
          message: 'Sync tracking successfully.',
          type: 'success'
        })
      } catch (error) {
        console.error('syncTracking', error)
        this.setSnackbar({
          value: true,
          message: `[SYNC-PICK-UP-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    getDateFormat (date) {
      if (date) {
        return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
      }
      return '-'
    },
    find () {
      this.fetchDhlReports(true)
      this.fetchDhlSummary()
    },
    async exportDataToCSV () {
      try {
        this.setLoading({ active: true })
        const query = {
          page: 1,
          limit: 99999,
          startDate: this.filter.dates[0],
          endDate: this.filter.dates[1],
          warehouse: this.filter.warehouse,
          state: this.filter.state,
          search: this.filter.searchInput
        }

        const { data } = await DHLService.getExpressPickup(query)
        const reportCsv = data.results

        const items = reportCsv.map((report) => (
          {
            orderId: report.orderId,
            shipmentNo: report.shipmentNo,
            pieces: report?.pieces.length || 0
          }
        ))

        const options = {
          filename: `DHLExpress_Pickup_Report_${this.filter.dates[0]}-${this.filter.dates[1]}`,
          showLabels: false,
          useKeysAsHeaders: false
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv([
          {
            orderId: 'รหัสออเดอร์',
            shipmentNo: 'หมายเลขจัดส่ง',
            pieces: 'จำนวน'
          },
          ...items
        ])
      } catch (error) {
        console.error('fetchProductsToExport', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style scoped>
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.header {
  font-size: 1.5rem;
  font-weight: bold;
}
.bg-white {
  background-color: white !important;
}
</style>
